import * as React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <section className="section--row section--spacious hcenter">

            <h1 style={{fontSize: "100px", marginBottom: "50px"}}>404</h1>
            <p className={"head"}>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </section>
    </Layout>
)

export const Head = () => <Seo title="404 Page Not Found"/>

export default NotFoundPage

